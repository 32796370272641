import { default as indexz9opwA8Uv8Meta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/index.vue?macro=true";
import { default as aboutLvWEsVO2nAMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/about.vue?macro=true";
import { default as _91id_93S04ouHbf2cMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/bookings/[id].vue?macro=true";
import { default as editwXPbKYx1B6Meta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/edit.vue?macro=true";
import { default as indexOvfIqlMWAnMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]-[car]/index.vue?macro=true";
import { default as failed36MsL1e1rlMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/failed.vue?macro=true";
import { default as indexutHO3ac336Meta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/index.vue?macro=true";
import { default as successxilCs7TqbRMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/success.vue?macro=true";
import { default as indexyw9r2L0q1qMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/index.vue?macro=true";
import { default as _91_46_46_46slug_93ElNeJykC5OMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/blog/[...slug].vue?macro=true";
import { default as indexlPjIfQeqCRMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/blog/index.vue?macro=true";
import { default as booklgytuBREMvMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/book.vue?macro=true";
import { default as failedurTmFNOer0Meta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/failed.vue?macro=true";
import { default as indexyctte16oiFMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/index.vue?macro=true";
import { default as successFFSVGY6jgYMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/success.vue?macro=true";
import { default as indexqMBHymFTSXMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/index.vue?macro=true";
import { default as contact3ivmZbkxZPMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/contact.vue?macro=true";
import { default as faqVahF0CHuh4Meta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/faq.vue?macro=true";
import { default as howcVP58TnJviMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/how.vue?macro=true";
import { default as loginvj17cver3YMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/login.vue?macro=true";
import { default as logout2vUdGuvYL0Meta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/logout.vue?macro=true";
import { default as termsA9H6WAYHIEMeta } from "/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/terms.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexz9opwA8Uv8Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/index.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/about.vue")
  },
  {
    name: "about___de",
    path: "/de/ueber-uns",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/about.vue")
  },
  {
    name: "about___fr",
    path: "/fr/a-propos",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/about.vue")
  },
  {
    name: "about___it",
    path: "/it/chi-siamo",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/about.vue")
  },
  {
    name: "account-bookings-id___en",
    path: "/en/account/bookings/:id()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/bookings/[id].vue")
  },
  {
    name: "account-bookings-id___de",
    path: "/de/account/bookings/:id()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/bookings/[id].vue")
  },
  {
    name: "account-bookings-id___fr",
    path: "/fr/account/bookings/:id()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/bookings/[id].vue")
  },
  {
    name: "account-bookings-id___it",
    path: "/it/account/bookings/:id()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/bookings/[id].vue")
  },
  {
    name: "account-edit___en",
    path: "/en/account/edit",
    meta: editwXPbKYx1B6Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/edit.vue")
  },
  {
    name: "account-edit___de",
    path: "/de/konto/bearbeiten",
    meta: editwXPbKYx1B6Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/edit.vue")
  },
  {
    name: "account-edit___fr",
    path: "/fr/compte/modifier",
    meta: editwXPbKYx1B6Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/edit.vue")
  },
  {
    name: "account-edit___it",
    path: "/it/account/modificare",
    meta: editwXPbKYx1B6Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/edit.vue")
  },
  {
    name: "account-extend-booking-car___en",
    path: "/en/account/extend/:booking()-:car()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]-[car]/index.vue")
  },
  {
    name: "account-extend-booking-car___de",
    path: "/de/account/extend/:booking()-:car()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]-[car]/index.vue")
  },
  {
    name: "account-extend-booking-car___fr",
    path: "/fr/account/extend/:booking()-:car()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]-[car]/index.vue")
  },
  {
    name: "account-extend-booking-car___it",
    path: "/it/account/extend/:booking()-:car()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]-[car]/index.vue")
  },
  {
    name: "account-extend-booking-failed___en",
    path: "/en/account/extend/:booking()/failed",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/failed.vue")
  },
  {
    name: "account-extend-booking-failed___de",
    path: "/de/konto/verlaengern/:booking()/fehlgeschlagen",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/failed.vue")
  },
  {
    name: "account-extend-booking-failed___fr",
    path: "/fr/compte/prolonger/:booking()/echec",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/failed.vue")
  },
  {
    name: "account-extend-booking-failed___it",
    path: "/it/account/estendere/:booking()/fallito",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/failed.vue")
  },
  {
    name: "account-extend-booking___en",
    path: "/en/account/extend/:booking()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/index.vue")
  },
  {
    name: "account-extend-booking___de",
    path: "/de/konto/verlaengern/:booking()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/index.vue")
  },
  {
    name: "account-extend-booking___fr",
    path: "/fr/compte/prolonger/:booking()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/index.vue")
  },
  {
    name: "account-extend-booking___it",
    path: "/it/account/estendere/:booking()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/index.vue")
  },
  {
    name: "account-extend-booking-success___en",
    path: "/en/account/extend/:booking()/success",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/success.vue")
  },
  {
    name: "account-extend-booking-success___de",
    path: "/de/konto/verlaengern/:booking()/erfolgreich",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/success.vue")
  },
  {
    name: "account-extend-booking-success___fr",
    path: "/fr/compte/prolonger/:booking()/succes",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/success.vue")
  },
  {
    name: "account-extend-booking-success___it",
    path: "/it/account/estendere/:booking()/successo",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/extend/[booking]/success.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: indexyw9r2L0q1qMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/index.vue")
  },
  {
    name: "account___de",
    path: "/de/konto",
    meta: indexyw9r2L0q1qMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/index.vue")
  },
  {
    name: "account___fr",
    path: "/fr/compte",
    meta: indexyw9r2L0q1qMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/index.vue")
  },
  {
    name: "account___it",
    path: "/it/account",
    meta: indexyw9r2L0q1qMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/account/index.vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug(.*)*",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug(.*)*",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug(.*)*",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___it",
    path: "/it/blog/:slug(.*)*",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/blog/[...slug].vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/blog/index.vue")
  },
  {
    name: "blog___it",
    path: "/it/blog",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/blog/index.vue")
  },
  {
    name: "cars-id-book___en",
    path: "/en/cars/:id()/book",
    meta: booklgytuBREMvMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/book.vue")
  },
  {
    name: "cars-id-book___de",
    path: "/de/autos/:id()/buchen",
    meta: booklgytuBREMvMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/book.vue")
  },
  {
    name: "cars-id-book___fr",
    path: "/fr/voitures/:id()/reserver",
    meta: booklgytuBREMvMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/book.vue")
  },
  {
    name: "cars-id-book___it",
    path: "/it/auto/:id()/prenotare",
    meta: booklgytuBREMvMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/book.vue")
  },
  {
    name: "cars-id-failed___en",
    path: "/en/cars/:id()/failed",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/failed.vue")
  },
  {
    name: "cars-id-failed___de",
    path: "/de/autos/:id()/fehlgeschlagen",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/failed.vue")
  },
  {
    name: "cars-id-failed___fr",
    path: "/fr/voitures/:id()/echec",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/failed.vue")
  },
  {
    name: "cars-id-failed___it",
    path: "/it/auto/:id()/fallito",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/failed.vue")
  },
  {
    name: "cars-id___en",
    path: "/en/cars/:id()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/index.vue")
  },
  {
    name: "cars-id___de",
    path: "/de/autos/:id()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/index.vue")
  },
  {
    name: "cars-id___fr",
    path: "/fr/voitures/:id()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/index.vue")
  },
  {
    name: "cars-id___it",
    path: "/it/auto/:id()",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/index.vue")
  },
  {
    name: "cars-id-success___en",
    path: "/en/cars/:id()/success",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/success.vue")
  },
  {
    name: "cars-id-success___de",
    path: "/de/autos/:id()/erfolgreich",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/success.vue")
  },
  {
    name: "cars-id-success___fr",
    path: "/fr/voitures/:id()/succes",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/success.vue")
  },
  {
    name: "cars-id-success___it",
    path: "/it/auto/:id()/successo",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/[id]/success.vue")
  },
  {
    name: "cars___en",
    path: "/en/cars",
    meta: indexqMBHymFTSXMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/index.vue")
  },
  {
    name: "cars___de",
    path: "/de/autos",
    meta: indexqMBHymFTSXMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/index.vue")
  },
  {
    name: "cars___fr",
    path: "/fr/voitures",
    meta: indexqMBHymFTSXMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/index.vue")
  },
  {
    name: "cars___it",
    path: "/it/auto",
    meta: indexqMBHymFTSXMeta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/cars/index.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/contact.vue")
  },
  {
    name: "contact___it",
    path: "/it/contatto",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/contact.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    meta: faqVahF0CHuh4Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    meta: faqVahF0CHuh4Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    meta: faqVahF0CHuh4Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/faq.vue")
  },
  {
    name: "faq___it",
    path: "/it/faq",
    meta: faqVahF0CHuh4Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/faq.vue")
  },
  {
    name: "how___en",
    path: "/en/how-it-works",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/how.vue")
  },
  {
    name: "how___de",
    path: "/de/so-funktionierts",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/how.vue")
  },
  {
    name: "how___fr",
    path: "/fr/comment-ca-marche",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/how.vue")
  },
  {
    name: "how___it",
    path: "/it/come-funziona",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/how.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexz9opwA8Uv8Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexz9opwA8Uv8Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexz9opwA8Uv8Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    meta: indexz9opwA8Uv8Meta || {},
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/login.vue")
  },
  {
    name: "login___de",
    path: "/de/login",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/login.vue")
  },
  {
    name: "login___it",
    path: "/it/login",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/login.vue")
  },
  {
    name: "logout___en",
    path: "/en/logout",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/logout.vue")
  },
  {
    name: "logout___de",
    path: "/de/logout",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/logout.vue")
  },
  {
    name: "logout___fr",
    path: "/fr/logout",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/logout.vue")
  },
  {
    name: "logout___it",
    path: "/it/logout",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/logout.vue")
  },
  {
    name: "terms___en",
    path: "/en/terms",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/terms.vue")
  },
  {
    name: "terms___de",
    path: "/de/agb",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/terms.vue")
  },
  {
    name: "terms___fr",
    path: "/fr/conditions-generales",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/terms.vue")
  },
  {
    name: "terms___it",
    path: "/it/termini-e-condizioni",
    component: () => import("/vercel/path0/node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/pages/terms.vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/kontakt",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/lieferwagen-mieten",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/lieferwagen-mieten/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/uber-uns",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/uber-uns/",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/account",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub5X4Ei38PMg
  }
]