
// @ts-nocheck
import locale__vercel_path0_lang_en_json from "../lang/en.json";
import locale__vercel_path0_lang_de_json from "../lang/de.json";
import locale__vercel_path0_lang_fr_json from "../lang/fr.json";
import locale__vercel_path0_lang_it_json from "../lang/it.json";


export const localeCodes =  [
  "en",
  "de",
  "fr",
  "it"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en.json", load: () => Promise.resolve(locale__vercel_path0_lang_en_json), cache: true }],
  "de": [{ key: "../lang/de.json", load: () => Promise.resolve(locale__vercel_path0_lang_de_json), cache: true }],
  "fr": [{ key: "../lang/fr.json", load: () => Promise.resolve(locale__vercel_path0_lang_fr_json), cache: true }],
  "it": [{ key: "../lang/it.json", load: () => Promise.resolve(locale__vercel_path0_lang_it_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../node_modules/.c12/github_robinrobinrobinrobin_the_f8jQ6p1Lip/i18n.config.ts?hash=6882c634&config=1" /* webpackChunkName: "__i18n_config_ts_6882c634" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "language": "en-US",
      "files": [
        "/vercel/path0/lang/en.json"
      ]
    },
    {
      "code": "de",
      "language": "de-DE",
      "files": [
        "/vercel/path0/lang/de.json"
      ]
    },
    {
      "code": "fr",
      "language": "fr-FR",
      "files": [
        "/vercel/path0/lang/fr.json"
      ]
    },
    {
      "code": "it",
      "language": "it-IT",
      "files": [
        "/vercel/path0/lang/it.json"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": "./lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://thehireguys.ch",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "account/index": {
      "en": "/account",
      "de": "/konto",
      "fr": "/compte",
      "it": "/account"
    },
    "account/bookings/index": {
      "en": "/account/bookings",
      "de": "/konto/buchungen",
      "fr": "/compte/reservations",
      "it": "/account/prenotazioni"
    },
    "account/bookings/[id]/index": {
      "en": "/account/bookings/[id]",
      "de": "/konto/buchungen/[id]",
      "fr": "/compte/reservations/[id]",
      "it": "/account/prenotazioni/[id]"
    },
    "account/extend/[booking]/index": {
      "en": "/account/extend/[booking]/",
      "de": "/konto/verlaengern/[booking]/",
      "fr": "/compte/prolonger/[booking]/",
      "it": "/account/estendere/[booking]/"
    },
    "account/extend/[booking]/failed": {
      "en": "/account/extend/[booking]/failed",
      "de": "/konto/verlaengern/[booking]/fehlgeschlagen",
      "fr": "/compte/prolonger/[booking]/echec",
      "it": "/account/estendere/[booking]/fallito"
    },
    "account/extend/[booking]/success": {
      "en": "/account/extend/[booking]/success",
      "de": "/konto/verlaengern/[booking]/erfolgreich",
      "fr": "/compte/prolonger/[booking]/succes",
      "it": "/account/estendere/[booking]/successo"
    },
    "account/edit": {
      "en": "/account/edit",
      "de": "/konto/bearbeiten",
      "fr": "/compte/modifier",
      "it": "/account/modificare"
    },
    "register": {
      "en": "/register",
      "de": "/registrieren",
      "fr": "/inscription",
      "it": "/registrazione"
    },
    "cars/index": {
      "en": "/cars",
      "de": "/autos",
      "fr": "/voitures",
      "it": "/auto"
    },
    "cars/[id]/index": {
      "en": "/cars/[id]/",
      "de": "/autos/[id]/",
      "fr": "/voitures/[id]/",
      "it": "/auto/[id]/"
    },
    "cars/[id]/book": {
      "en": "/cars/[id]/book",
      "de": "/autos/[id]/buchen",
      "fr": "/voitures/[id]/reserver",
      "it": "/auto/[id]/prenotare"
    },
    "cars/[id]/success": {
      "en": "/cars/[id]/success",
      "de": "/autos/[id]/erfolgreich",
      "fr": "/voitures/[id]/succes",
      "it": "/auto/[id]/successo"
    },
    "cars/[id]/failed": {
      "en": "/cars/[id]/failed",
      "de": "/autos/[id]/fehlgeschlagen",
      "fr": "/voitures/[id]/echec",
      "it": "/auto/[id]/fallito"
    },
    "how": {
      "en": "/how-it-works",
      "de": "/so-funktionierts",
      "fr": "/comment-ca-marche",
      "it": "/come-funziona"
    },
    "about": {
      "en": "/about",
      "de": "/ueber-uns",
      "fr": "/a-propos",
      "it": "/chi-siamo"
    },
    "contact": {
      "en": "/contact",
      "de": "/kontakt",
      "fr": "/contact",
      "it": "/contatto"
    },
    "faq": {
      "en": "/faq",
      "de": "/faq",
      "fr": "/faq",
      "it": "/faq"
    },
    "terms": {
      "en": "/terms",
      "de": "/agb",
      "fr": "/conditions-generales",
      "it": "/termini-e-condizioni"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en-US",
    "files": [
      {
        "path": "/vercel/path0/lang/en.json"
      }
    ]
  },
  {
    "code": "de",
    "language": "de-DE",
    "files": [
      {
        "path": "/vercel/path0/lang/de.json"
      }
    ]
  },
  {
    "code": "fr",
    "language": "fr-FR",
    "files": [
      {
        "path": "/vercel/path0/lang/fr.json"
      }
    ]
  },
  {
    "code": "it",
    "language": "it-IT",
    "files": [
      {
        "path": "/vercel/path0/lang/it.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
